import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7bc5ee1a = () => interopDefault(import('../pages/contest.vue' /* webpackChunkName: "pages/contest" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _ca324bc8 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _5f6c2827 = () => interopDefault(import('../pages/inventor.vue' /* webpackChunkName: "pages/inventor" */))
const _38483854 = () => interopDefault(import('../pages/live.vue' /* webpackChunkName: "pages/live" */))
const _0deb21c2 = () => interopDefault(import('../pages/live/_hall.vue' /* webpackChunkName: "pages/live/_hall" */))
const _076ad200 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _5f9301d6 = () => interopDefault(import('../pages/partners.vue' /* webpackChunkName: "pages/partners" */))
const _7120d68d = () => interopDefault(import('../pages/program/index.vue' /* webpackChunkName: "pages/program/index" */))
const _3429c641 = () => interopDefault(import('../pages/speakers/index.vue' /* webpackChunkName: "pages/speakers/index" */))
const _29f8dfcd = () => interopDefault(import('../pages/user/index.ts' /* webpackChunkName: "pages/user/index" */))
const _7364f08e = () => interopDefault(import('../pages/users.vue' /* webpackChunkName: "pages/users" */))
const _3fdcfe1b = () => interopDefault(import('../pages/auth/change-password.vue' /* webpackChunkName: "pages/auth/change-password" */))
const _1c75ff9a = () => interopDefault(import('../pages/auth/impersonalize.vue' /* webpackChunkName: "pages/auth/impersonalize" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _23d2ff46 = () => interopDefault(import('../pages/auth/registration.vue' /* webpackChunkName: "pages/auth/registration" */))
const _c4bfe6f0 = () => interopDefault(import('../pages/user/calendar.vue' /* webpackChunkName: "pages/user/calendar" */))
const _78e0b0a2 = () => interopDefault(import('../pages/user/chat.vue' /* webpackChunkName: "pages/user/chat" */))
const _391991ba = () => interopDefault(import('../pages/user/contest.vue' /* webpackChunkName: "pages/user/contest" */))
const _78875b2f = () => interopDefault(import('../pages/user/payment/index.vue' /* webpackChunkName: "pages/user/payment/index" */))
const _965279dc = () => interopDefault(import('../pages/user/profile/index.vue' /* webpackChunkName: "pages/user/profile/index" */))
const _777e8881 = () => interopDefault(import('../pages/user/recommended.vue' /* webpackChunkName: "pages/user/recommended" */))
const _453ea0fc = () => interopDefault(import('../pages/user/videocourse.vue' /* webpackChunkName: "pages/user/videocourse" */))
const _0d20bd60 = () => interopDefault(import('../pages/user/payment/participant.vue' /* webpackChunkName: "pages/user/payment/participant" */))
const _f050940c = () => interopDefault(import('../pages/user/profile/edit.vue' /* webpackChunkName: "pages/user/profile/edit" */))
const _2a6fc7db = () => interopDefault(import('../pages/auth/oauth/_provider.vue' /* webpackChunkName: "pages/auth/oauth/_provider" */))
const _b93170e4 = () => interopDefault(import('../pages/group/_alias/index.vue' /* webpackChunkName: "pages/group/_alias/index" */))
const _69beb993 = () => interopDefault(import('../pages/news/_alias.vue' /* webpackChunkName: "pages/news/_alias" */))
const _e09f28b4 = () => interopDefault(import('../pages/program/_alias.vue' /* webpackChunkName: "pages/program/_alias" */))
const _2dc57272 = () => interopDefault(import('../pages/speakers/_alias.vue' /* webpackChunkName: "pages/speakers/_alias" */))
const _38c45514 = () => interopDefault(import('../pages/group/_alias/_id.vue' /* webpackChunkName: "pages/group/_alias/_id" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contest",
    component: _7bc5ee1a,
    name: "contest___ru"
  }, {
    path: "/en",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/faq",
    component: _ca324bc8,
    name: "faq___ru"
  }, {
    path: "/inventor",
    component: _5f6c2827,
    name: "inventor___ru"
  }, {
    path: "/live",
    component: _38483854,
    name: "live___ru",
    children: [{
      path: ":hall?",
      component: _0deb21c2,
      name: "live-hall___ru"
    }]
  }, {
    path: "/news",
    component: _076ad200,
    name: "news___ru"
  }, {
    path: "/partners",
    component: _5f9301d6,
    name: "partners___ru"
  }, {
    path: "/program",
    component: _7120d68d,
    name: "program___ru"
  }, {
    path: "/speakers",
    component: _3429c641,
    name: "speakers___ru"
  }, {
    path: "/user",
    component: _29f8dfcd,
    name: "user___ru"
  }, {
    path: "/users",
    component: _7364f08e,
    name: "users___ru"
  }, {
    path: "/auth/change-password",
    component: _3fdcfe1b,
    name: "auth-change-password___ru"
  }, {
    path: "/auth/impersonalize",
    component: _1c75ff9a,
    name: "auth-impersonalize___ru"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login___ru"
  }, {
    path: "/auth/registration",
    component: _23d2ff46,
    name: "auth-registration___ru"
  }, {
    path: "/en/contest",
    component: _7bc5ee1a,
    name: "contest___en"
  }, {
    path: "/en/faq",
    component: _ca324bc8,
    name: "faq___en"
  }, {
    path: "/en/inventor",
    component: _5f6c2827,
    name: "inventor___en"
  }, {
    path: "/en/live",
    component: _38483854,
    name: "live___en",
    children: [{
      path: ":hall?",
      component: _0deb21c2,
      name: "live-hall___en"
    }]
  }, {
    path: "/en/news",
    component: _076ad200,
    name: "news___en"
  }, {
    path: "/en/partners",
    component: _5f9301d6,
    name: "partners___en"
  }, {
    path: "/en/program",
    component: _7120d68d,
    name: "program___en"
  }, {
    path: "/en/speakers",
    component: _3429c641,
    name: "speakers___en"
  }, {
    path: "/en/user",
    component: _29f8dfcd,
    name: "user___en"
  }, {
    path: "/en/users",
    component: _7364f08e,
    name: "users___en"
  }, {
    path: "/user/calendar",
    component: _c4bfe6f0,
    name: "user-calendar___ru"
  }, {
    path: "/user/chat",
    component: _78e0b0a2,
    name: "user-chat___ru"
  }, {
    path: "/user/contest",
    component: _391991ba,
    name: "user-contest___ru"
  }, {
    path: "/user/payment",
    component: _78875b2f,
    name: "user-payment___ru"
  }, {
    path: "/user/profile",
    component: _965279dc,
    name: "user-profile___ru"
  }, {
    path: "/user/recommended",
    component: _777e8881,
    name: "user-recommended___ru"
  }, {
    path: "/user/videocourse",
    component: _453ea0fc,
    name: "user-videocourse___ru"
  }, {
    path: "/en/auth/change-password",
    component: _3fdcfe1b,
    name: "auth-change-password___en"
  }, {
    path: "/en/auth/impersonalize",
    component: _1c75ff9a,
    name: "auth-impersonalize___en"
  }, {
    path: "/en/auth/login",
    component: _8e1272a8,
    name: "auth-login___en"
  }, {
    path: "/en/auth/registration",
    component: _23d2ff46,
    name: "auth-registration___en"
  }, {
    path: "/en/user/calendar",
    component: _c4bfe6f0,
    name: "user-calendar___en"
  }, {
    path: "/en/user/chat",
    component: _78e0b0a2,
    name: "user-chat___en"
  }, {
    path: "/en/user/contest",
    component: _391991ba,
    name: "user-contest___en"
  }, {
    path: "/en/user/payment",
    component: _78875b2f,
    name: "user-payment___en"
  }, {
    path: "/en/user/profile",
    component: _965279dc,
    name: "user-profile___en"
  }, {
    path: "/en/user/recommended",
    component: _777e8881,
    name: "user-recommended___en"
  }, {
    path: "/en/user/videocourse",
    component: _453ea0fc,
    name: "user-videocourse___en"
  }, {
    path: "/user/payment/participant",
    component: _0d20bd60,
    name: "user-payment-participant___ru"
  }, {
    path: "/user/profile/edit",
    component: _f050940c,
    name: "user-profile-edit___ru"
  }, {
    path: "/en/user/payment/participant",
    component: _0d20bd60,
    name: "user-payment-participant___en"
  }, {
    path: "/en/user/profile/edit",
    component: _f050940c,
    name: "user-profile-edit___en"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___ru"
  }, {
    path: "/en/auth/oauth/:provider?",
    component: _2a6fc7db,
    name: "auth-oauth-provider___en"
  }, {
    path: "/auth/oauth/:provider?",
    component: _2a6fc7db,
    name: "auth-oauth-provider___ru"
  }, {
    path: "/en/group/:alias",
    component: _b93170e4,
    name: "group-alias___en"
  }, {
    path: "/en/news/:alias",
    component: _69beb993,
    name: "news-alias___en"
  }, {
    path: "/en/program/:alias",
    component: _e09f28b4,
    name: "program-alias___en"
  }, {
    path: "/en/speakers/:alias",
    component: _2dc57272,
    name: "speakers-alias___en"
  }, {
    path: "/en/group/:alias?/:id",
    component: _38c45514,
    name: "group-alias-id___en"
  }, {
    path: "/group/:alias",
    component: _b93170e4,
    name: "group-alias___ru"
  }, {
    path: "/news/:alias",
    component: _69beb993,
    name: "news-alias___ru"
  }, {
    path: "/program/:alias",
    component: _e09f28b4,
    name: "program-alias___ru"
  }, {
    path: "/speakers/:alias",
    component: _2dc57272,
    name: "speakers-alias___ru"
  }, {
    path: "/group/:alias?/:id",
    component: _38c45514,
    name: "group-alias-id___ru"
  }, {
    path: "/en/*",
    component: _2efaf336,
    name: "all___en"
  }, {
    path: "/*",
    component: _2efaf336,
    name: "all___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
