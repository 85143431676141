import { wrap } from "../_"

export interface CreateSessionQuestionData {
  session: string
  person: string
  text: string
}

const pfx = "/program"

export default (a: NuxtAxiosInstance) => ({
  getTracks: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/track`,
      { cache: true, ...cfg })),

  getDays: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/day`,
      { cache: true, ...cfg })),

  getHalls: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/hall`,
      { cache: true, ...cfg })),

  getSessionQuestions: (sessionAlias: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/session/${sessionAlias}/question`,
      { ...cfg })),

  createSessionQuestion: (data: CreateSessionQuestionData, cfg?: AxiosConfig) =>
    wrap(
      a.$post(`${pfx}/session/question`, data,
        { ...cfg })),

  addQuestionVote: (questionId: string, value: number, cfg?: AxiosConfig) =>
    wrap(
      a.$post(`${pfx}/question/${questionId}/vote`, { value },
        { ...cfg })),

  getParticipants: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/participant`,
      { cache: true, ...cfg })),

  getRecommendedSessions: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/session/recommended`,
      { ...cfg })),

  getSessions: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/session`,
      { cache: true, ...cfg })),

  getMySessions: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/session/person`,
      { ...cfg })),

  getSession: (sessionAlias: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/session/${sessionAlias}`,
      { cache: true, ...cfg })),

  getHallLiveSession: (hallAlias: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/session/live/${hallAlias}`,
      { ...cfg })),

  getSessionByCode: (code: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/session/code/${code}`,
      { cache: true, ...cfg })),

  getFavoriteSessions: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/session/favorite`,
      { ...cfg })),

  addFavoriteSession: (sessionAlias: string, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/session/${sessionAlias}/favorite`, {},
      { ...cfg })),

  removeFavoriteSession: (sessionAlias: string, cfg?: AxiosConfig) => wrap(
    a.$delete(`${pfx}/session/${sessionAlias}/favorite`,
      { ...cfg })),

  addSessionVote: (sessionAlias: string, value: number, cfg?: AxiosConfig) =>
    wrap(
      a.$post(`${pfx}/session/${sessionAlias}/vote`, { value },
        { ...cfg })),

  getSessionVotes: (sessionAlias: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/session/${sessionAlias}/vote`,
      { ...cfg })),

  getGroups: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/group`,
      { cache: true, ...cfg })),

  getEvents: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/event`,
      { cache: true, ...cfg })),

  getEvent: (id: string, cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/event/${id}`,
      { cache: true, ...cfg })),

  getFavoriteEvents: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/event/participation`,
      { ...cfg })),

  addFavoriteEvent: (eventId: string, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/event/${eventId}/participate`, {},
      { ...cfg })),

  removeFavoriteEvent: (eventId: string, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/event/${eventId}/cancel`, {},
      { ...cfg })),
})
