// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Vo1wGoMN{display:flex;flex-direction:column;min-height:100vh}.V2I-u8Cp,.YCqzj97X{display:flex;flex-direction:column;flex-grow:1}.YCqzj97X{width:100%}.RKExnlqc{max-width:100%;padding:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"xl": "1920.98",
	"container": "Vo1wGoMN",
	"content": "V2I-u8Cp",
	"inner": "YCqzj97X",
	"innerFullWidth": "RKExnlqc"
};
module.exports = ___CSS_LOADER_EXPORT___;
