// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hhWljf71{display:flex;justify-content:space-between}.hhWljf71>*+*{margin-left:max(3rem,min(11.41vw,13.69rem))}@media(max-width:1023.98px){.hhWljf71{display:block}.hhWljf71>*+*{margin-left:0;margin-top:2rem}}@media(max-width:667.98px){.hhWljf71 br{display:none}}.LFuyuBE3>*+*{margin-top:max(1rem,min(1.25vw,1.5rem))}.YQm23N5A{flex-basis:25.81rem}.YQm23N5A>*+*{margin-top:max(1.5rem,min(2.92vw,3.5rem))}.IRc7iPpL{color:var(--color-gray-4);font-size:max(1rem,min(1.04vw,1.25rem));font-weight:600;letter-spacing:.09em;line-height:1.2}.LAFIATU5,.IRc7iPpL{text-transform:uppercase}.LAFIATU5{color:#32388c;font-size:max(2rem,min(3.65vw,4.38rem));font-weight:400;line-height:1}.LAFIATU5:not(:last-child){margin-bottom:max(1.5rem,min(3.13vw,3.75rem))}._3Bi\\+-70A{color:var(--color-gray-4);font-size:.88rem;font-weight:500;line-height:1.6}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"xl": "1920.98",
	"container": "hhWljf71",
	"containerStart": "LFuyuBE3",
	"containerEnd": "YQm23N5A",
	"subheading": "IRc7iPpL",
	"heading": "LAFIATU5",
	"text": "_3Bi+-70A"
};
module.exports = ___CSS_LOADER_EXPORT___;
