
import Vue, { toRaw } from "vue"
import { mapState } from "vuex"
import pickBy from "lodash/pickBy"

interface Contact {
  title: string
  items: {
    label?: string
    value: string
    type: "url" | "email" | "tel"
  }[]
}

export default Vue.extend({
  data: () => ({
    contacts: toRaw([
      {
        title: "ВОИРФЕСТ",
        items: [
          { value: "https://voirfest.ru", type: "url" },
          { label: "Общие вопросы", value: "info@voirfest.ru", type: "email" },
          { label: "Пресс-служба", value: "pr@voirfest.ru", type: "email" },
        ],
      },
      {
        title: "ВОИР",
        items: [
          { value: "https://voir-russia.ru", type: "url" },
          { value: "+7 (958) 146-95-57", type: "tel" },
        ],
      },
    ] as Contact[]),
  }),

  computed: {
    ...mapState("app", ["event"]),
    socials (): Record<string, string> {
      return pickBy(this.event?.links || {}, Boolean)
    },
  },

  methods: {
    getHref (value: string, type: Contact["items"][number]["type"]): string {
      let pfx = ""
      if (type === "email") pfx = "mailto:"
      else if (type === "tel") pfx = "tel:"
      return pfx + value
    },

    cleanUrl (url: string): string {
      return url.replace(/^https?:\/\//, "")
    },
  },
})
